/* You can add global styles to this file, and also import other style files */
body{
    background-color: #f0f0f0;
    font-family: 'Poppins';font-size: 14px;
}
.bodycontent{
  margin-top: 90px;
  margin-left: 109px;
}
.btn {
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
}
.btn.btn-cta {
    background: #F79122;
    border-color: #F79122;
    color: #fff;
    padding-left: 15px;
    padding-right: 15px;
    margin: 6px 0;
    margin-left: -3px;
}
.tablehead{
    background-color: #914573;
    color: #ffffff;
  }
.btn-Cancel{
    background-color: #d11a2a;
    color: aliceblue;
    font-weight: bold;

}
.btn-anvayaaYellow{
    background-color:#f89f37;
    font-weight: bold;
    color: white;
  }
.btn-anvayaaPurple{
    background-color: #914573;
    font-weight: bold;
    color: aliceblue;
  }

.anvayaaPurple-backgroundColor{
    background-color: #914573;
    font-weight: bold;
    color: aliceblue;
}

.text-Yellow{
     color:#f89f37 ;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.anvayaaButtonYelow{
  background-color: #f79022;
  font-weight: bold;
  color: aliceblue;
}
.anvayaabutton{
  background: #F79122;
  border-color: #F79122;
  color: #fff;
  /* padding-left: 15px;
  padding-right: 15px; */
  margin: 6px 0;
  margin-left: -3px;
  border-radius: 25px;
  cursor: pointer;


}
.anvayaabutton-primary {
  width: auto;
  min-width: 100px;
  background:
  #F79123;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px 5px;
  /* margin: 10px 5px; */
  margin: 6px 0;
  margin-left: -3px;
}
.anvayaabutton-secondary {
  width: 100px ;
  background:
  #914573;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px 5px;
  /* margin: 10px 5px; */
  margin: 6px 0;
  margin-left: -3px;
}
.anvayaabutton-primary-lg{
  width: 100px;
  background:
  #F79123;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 50px;
  cursor: pointer;
  padding: 15px 5px;
  margin: 15px 5px;
}
.anvayaabutton-primary-sm{
  width: 100px ;
  background:
  #F79123;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 50px;
  cursor: pointer;
  padding: 5px 5px;
  margin: 5px 5px;
}
.anvayaabutton-secondary-lg{
  width: 100px ;
  background:
  #914573;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 10px;
  cursor: pointer;
  padding: 15px 5px;
  margin: 15px 5px;
}
.anvayaabutton-secondary-sm{
  width: 100px ;
  background:
  #914573;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 50px;
  cursor: pointer;
  padding: 5px 5px;
  margin: 5px 5px;
}
.Loignbutton {
  background-color: #f79122;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width:100%;
  height: 50px;
  border-radius: 10px;
}
.btn.btn-cta {
  background: #F79122;
  border-color: #F79122;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  margin: 6px 0;
  margin-left: -3px;
}
.pointer {cursor: pointer;}

:root {
  --offcanvas-horizontal-width: 700px;
}